import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { possibleTypes } from './possibleTypes.json';
import { STORAGE_KEYS } from '../constants';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_ENDPOINT_TESTING_API,
});

const jwtTokenLink = setContext((_, context) => {
  const jwtToken = context.jwtToken ?? localStorage.getItem(STORAGE_KEYS.JWT_TOKEN);

  return {
    headers: {
      ...context.headers,
      ['authorization']: jwtToken ? `Bearer ${jwtToken}` : '',
    },
  };
});

export const apolloClient = new ApolloClient({
  link: jwtTokenLink.concat(httpLink),
  cache: new InMemoryCache({
    possibleTypes,
  }),
});
