import { AppContextInterface, AppReducerState } from '../contexts/app/context';
import { SessionFragment } from '../graphql/operations';

export const audioArePreloaded = (audioFileUrl: string, preloadedAudios: AppReducerState['preloadedAudios']): boolean =>
  Object.keys(preloadedAudios).includes(audioFileUrl);

export const playAudio = (
  audio: HTMLAudioElement,
  options: { volume: number; currentTime?: number },
): HTMLAudioElement => {
  audio.volume = options.volume;
  audio.currentTime = options.currentTime || 0;
  audio.play();

  return audio;
};

export const getCurrentAudioPlaying = (audioElements: AppContextInterface['audioElements']): HTMLAudioElement =>
  Object.values(audioElements).find((audioElement) => !audioElement.paused);

export const stopAudiosPlaying = (audioElements: AppContextInterface['audioElements']): number => {
  const audioPlaying = getCurrentAudioPlaying(audioElements);
  if (audioPlaying) {
    audioPlaying.pause();
  }

  return audioPlaying?.currentTime || 0;
};

export const getCurrentAudioElementBySession = (
  session: SessionFragment,
  audioElements: AppContextInterface['audioElements'],
): undefined | HTMLAudioElement => audioElements[session?.currentStep?.audioFileUrl];

export const getNextAudioElementBySession = (
  session: SessionFragment,
  audioElements: AppContextInterface['audioElements'],
): undefined | HTMLAudioElement => audioElements[session?.nextStep?.audioFileUrl];
