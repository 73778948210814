import React from 'react';

import './ExampleBlock.scss';

export type ExampleBlockProps = {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  example: React.ReactNode;
  imageFileUrl?: string;
};

export const ExampleBlock: React.FC<ExampleBlockProps> = ({ title, subtitle, example, imageFileUrl }) => (
  <div className="example-block-of-part">
    <p className="example-block-of-part__title">{title}</p>
    <div className="example-block-of-part__content">
      {imageFileUrl && (
        <div className="example-block-of-part__image">
          <img src={imageFileUrl} alt={`${title} example`} />
        </div>
      )}
      <div>
        <p className="example-block-of-part__subtitle">{subtitle}</p>
        <div className="example-block-of-part__example">{example}</div>
      </div>
    </div>
  </div>
);
