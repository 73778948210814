import React, { FC } from 'react';
import { PartBlock } from '../../components/Blocks/PartBlock';
import { useAppContext } from '../../contexts/app';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../components/UI/Loader';
import { StepFragment, PartFragment } from '../../graphql/operations';

export const TestPartPage: FC = () => {
  const { t } = useTranslation();
  const { currentAudioIsPreloaded, session } = useAppContext();
  const currentStep = session?.currentStep as StepFragment & PartFragment;

  if (!currentAudioIsPreloaded) {
    return <Loader />;
  }

  return (
    <PartBlock
      title={currentStep?.label}
      subtitle={t('part_page.directions')}
      description={currentStep?.directions}
      example={
        currentStep?.example
          ? {
              title: t('part_page.example.title'),
              subtitle: t('part_page.example.sample_answer'),
              imageFileUrl: currentStep?.imageFileUrl,
              example: <div dangerouslySetInnerHTML={{ __html: currentStep?.example }} />,
            }
          : null
      }
    />
  );
};
