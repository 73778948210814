import React from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorBlock } from '../../components/Blocks/ErrorBlock';
import { useParams } from 'react-router';

export const ErrorPage: React.FC = () => {
  const { t } = useTranslation();
  const { statusCode = '404' } = useParams<{ statusCode?: string }>();

  return (
    <ErrorBlock
      metaTitle={t(`meta.title.error.${statusCode}`)}
      title={t(`error-page.${statusCode}.title`)}
      description={t(`error-page.${statusCode}.content`)}
      statusCode={statusCode}
    />
  );
};
