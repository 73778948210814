import produce, { Draft } from 'immer';

import { Action, ACTION_TYPE } from './action';
import { AppReducerState } from './context';
import { SessionStatusEnum, SetFragment, StepTypeEnum } from '../../graphql/operations';
import { STORAGE_KEYS } from '../../constants';
import { audioArePreloaded } from '../../utils';

const setAudiosArePreloaded = (draft: Draft<AppReducerState>): Draft<AppReducerState> => {
  const { currentStep, nextStep } = draft.session;
  if (currentStep?.audioFileUrl) {
    draft.currentAudioIsPreloaded = audioArePreloaded(currentStep.audioFileUrl, draft.preloadedAudios);
  }
  if (nextStep?.audioFileUrl) {
    draft.nextAudioIsPreloaded = audioArePreloaded(nextStep.audioFileUrl, draft.preloadedAudios);
  }

  return draft;
};

const updateAnswers = (draft: Draft<AppReducerState>): Draft<AppReducerState> => {
  const { currentStep } = draft.session;

  draft.answers = [];
  if (currentStep?.stepType === StepTypeEnum.Set) {
    const questions = (currentStep as SetFragment).questions;
    draft.answers = questions.map((question) => {
      return {
        questionId: question.id,
        answerKey: question.answerKeySent || null,
      };
    });
  }

  return draft;
};

export const reducer = produce((draft: Draft<AppReducerState>, action: Action) => {
  switch (action.type) {
    case ACTION_TYPE.SET_JWT_TOKEN:
      localStorage.clear();
      localStorage.setItem('jwtToken', action.jwtToken);
      draft.jwtToken = action.jwtToken;
      break;
    case ACTION_TYPE.SET_IS_LOADING:
      draft.appIsLoading = action.isLoading;
      break;
    case ACTION_TYPE.SET_PRELOADED_AUDIO: {
      draft.currentPreloadedAudioFileUrl = action.audioFileUrl;
      draft.preloadedAudios[action.audioFileUrl] = action.isPreloaded;
      draft = setAudiosArePreloaded(draft);
      break;
    }
    case ACTION_TYPE.CLEAR_SESSION:
      localStorage.clear();
      draft.jwtToken = null;
      draft.appIsLoading = false;
      break;
    case ACTION_TYPE.INIT_ME_AND_SESSION: {
      draft.appIsLoading = false;
      draft.modalIsOpened = false;
      draft.me = action.me;
      draft.answers = [];

      if (!draft.session && action.session.status === SessionStatusEnum.InProgress) {
        draft.modalIsOpened = true;
      }

      draft.session = action.session;
      draft = updateAnswers(draft);
      draft = setAudiosArePreloaded(draft);
      break;
    }
    case ACTION_TYPE.UPDATE_SESSION:
    case ACTION_TYPE.RESET_SESSION:
      draft.appIsLoading = false;
      draft.modalIsOpened = false;
      draft.session = action.session;
      draft = updateAnswers(draft);
      draft = setAudiosArePreloaded(draft);
      if (action.type === ACTION_TYPE.UPDATE_SESSION) {
        draft.nextAudioIsPreloaded = false;
      }
      break;
    case ACTION_TYPE.UPDATE_NEXT_STEP:
      if (!action.nextStep) {
        draft.session.nextStep = undefined;
        draft.nextAudioIsPreloaded = true;
      } else {
        draft.session.nextStep = action.nextStep;
        draft = setAudiosArePreloaded(draft);
      }
      break;
    case ACTION_TYPE.CHANGE_VOLUME:
      draft.audioVolume = action.volume;
      localStorage.setItem(STORAGE_KEYS.AUDIO_VOLUME, action.volume.toString());
      break;
    case ACTION_TYPE.UPDATE_ANSWER: {
      const { answerKey, questionId } = action.answer;
      const indexAnswersByQuestionId = draft.answers.findIndex(
        ({ questionId: currentQuestionId }) => currentQuestionId === questionId,
      );
      if (indexAnswersByQuestionId !== -1) {
        draft.answers[indexAnswersByQuestionId].answerKey = answerKey;
      } else {
        draft.answers.push({
          questionId: questionId,
          answerKey,
        });
      }
      break;
    }
    case ACTION_TYPE.OPEN_MODAL:
      draft.modalIsOpened = true;
      if (action?.currentAudioTime) {
        draft.session.currentAudioTime = action.currentAudioTime;
      }
      break;
    case ACTION_TYPE.CLOSE_MODAL:
      draft.modalIsOpened = false;
      break;
  }

  draft.nextStepIsDisabled = false;
  if (draft?.session?.currentStep?.stepType === StepTypeEnum.Set) {
    draft.nextStepIsDisabled = draft.answers.filter((answer) => answer.answerKey !== null).length === 0;
  }

  return draft;
});
