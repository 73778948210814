import { useLayoutEffect, useState } from 'react';

import { MATCH_MEDIA_QUERY_LIST } from '../constants';

export type MatchBreakpointsType = 'tablet' | 'desktop';

export const useMediaQuery = (query: MatchBreakpointsType): boolean => {
  const [matches, setMatches] = useState(false);

  useLayoutEffect(() => {
    const media = window.matchMedia(MATCH_MEDIA_QUERY_LIST[query]);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener('resize', listener);

    return () => window.removeEventListener('resize', listener);
  }, [query]);

  return matches;
};
