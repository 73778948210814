import React, { FC } from 'react';

import './Footer.scss';

export interface FooterProps {
  legalNoticeText: React.ReactNode;
  termsAndConditionsText: React.ReactNode;
  editCookiesSettingsText: React.ReactNode;
  copyrightText: React.ReactNode;
  hasCookieConsent: boolean;
}

export const Footer: FC<FooterProps> = ({
  legalNoticeText,
  termsAndConditionsText,
  editCookiesSettingsText,
  copyrightText,
  hasCookieConsent,
}) => (
  <footer className="footer">
    <ul className="footer__links">
      <li>{legalNoticeText}</li>
      <li>{termsAndConditionsText}</li>
      {hasCookieConsent && (
        <li>
          <button className="manage-consent">{editCookiesSettingsText}</button>
        </li>
      )}
    </ul>
    <p className="footer__copyright">{copyrightText}</p>
  </footer>
);
