import { InitOptions } from 'i18next';

import { AUTHORIZED_LANGUAGES, DEFAULT_LANGUAGE } from '../constants';

const i18nConfig = {
  load: 'languageOnly',
  preload: AUTHORIZED_LANGUAGES,
  whitelist: AUTHORIZED_LANGUAGES,
  fallbackLng: DEFAULT_LANGUAGE,
  returnEmptyString: false,
  defaultNS: 'messages',
  ns: 'messages',
  backend: {
    loadPath: `${process.env.REACT_APP_TRANSLATIONS_ENDPOINT_URI}/{{ns}}.{{lng}}.json`,
    crossDomain: true,
  },
  react: {
    bindI18n: 'languageChanged',
    bindI18nStore: false,
    useSuspense: true,
  },
  detection: {
    order: ['path'],
    lookupFromPathIndex: 0,
  },
} as InitOptions;

export default i18nConfig;
