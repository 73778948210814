import { AnswerInput, MeFragment, SessionFragment } from '../../graphql/operations';

export enum ACTION_TYPE {
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_PRELOADED_AUDIO = 'SET_PRELOADED_AUDIO',
  SET_JWT_TOKEN = 'SET_JWT_TOKEN',
  CLEAR_SESSION = 'CLEAR_SESSION',
  INIT_ME_AND_SESSION = 'INIT_ME_AND_SESSION',
  UPDATE_SESSION = 'UPDATE_SESSION',
  RESET_SESSION = 'RESET_SESSION',
  UPDATE_NEXT_STEP = 'UPDATE_NEXT_STEP',
  CHANGE_VOLUME = 'CHANGE_VOLUME',
  UPDATE_ANSWER = 'UPDATE_ANSWER',
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
}

export const actions = {
  setJwtToken: (jwtToken: string) =>
    <const>{
      type: ACTION_TYPE.SET_JWT_TOKEN,
      jwtToken,
    },
  clearSession: () =>
    <const>{
      type: ACTION_TYPE.CLEAR_SESSION,
    },
  setIsLoading: (isLoading: boolean) =>
    <const>{
      type: ACTION_TYPE.SET_IS_LOADING,
      isLoading,
    },
  setPreloadedAudio: (audioFileUrl: string, isPreloaded: boolean) =>
    <const>{
      type: ACTION_TYPE.SET_PRELOADED_AUDIO,
      audioFileUrl,
      isPreloaded,
    },
  // when app is initialized on first load, set fetched 'me' and 'session' info in the app state
  initMeAndSession: (options: { session: SessionFragment; me: MeFragment }) =>
    <const>{
      type: ACTION_TYPE.INIT_ME_AND_SESSION,
      ...options,
    },
  // when we click on next on each step, set the provisional session info from apollo cache in the app state
  updateSession: (options: { session: SessionFragment }) =>
    <const>{
      type: ACTION_TYPE.UPDATE_SESSION,
      ...options,
    },
  resetSession: (options: { session: SessionFragment }) =>
    <const>{
      type: ACTION_TYPE.RESET_SESSION,
      ...options,
    },
  // after clicking on next on each step, after we get the real result from the backend mutation, set the next step in the app state
  updateNextStep: (options: { nextStep: SessionFragment['nextStep'] }) =>
    <const>{
      type: ACTION_TYPE.UPDATE_NEXT_STEP,
      ...options,
    },
  changeVolume: (volume: number) =>
    <const>{
      type: ACTION_TYPE.CHANGE_VOLUME,
      volume,
    },
  updateAnswer: (answer: AnswerInput) =>
    <const>{
      type: ACTION_TYPE.UPDATE_ANSWER,
      answer,
    },
  openModal: (options: { currentAudioTime?: number } = {}) =>
    <const>{
      type: ACTION_TYPE.OPEN_MODAL,
      ...options,
    },
  closeModal: () =>
    <const>{
      type: ACTION_TYPE.CLOSE_MODAL,
    },
};

export type Action =
  | ReturnType<typeof actions.setJwtToken>
  | ReturnType<typeof actions.setIsLoading>
  | ReturnType<typeof actions.setPreloadedAudio>
  | ReturnType<typeof actions.clearSession>
  | ReturnType<typeof actions.initMeAndSession>
  | ReturnType<typeof actions.updateSession>
  | ReturnType<typeof actions.resetSession>
  | ReturnType<typeof actions.updateNextStep>
  | ReturnType<typeof actions.changeVolume>
  | ReturnType<typeof actions.updateAnswer>
  | ReturnType<typeof actions.openModal>
  | ReturnType<typeof actions.closeModal>;
