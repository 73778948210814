import React, { useEffect } from 'react';
import { Loader } from '../../components/UI/Loader';
import { useParams } from 'react-router';
import { actions, useAppContext } from '../../contexts/app';

export const SessionTokenPage: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const { dispatch } = useAppContext();

  useEffect(() => {
    if (!token) {
      dispatch(actions.clearSession());

      return;
    }

    dispatch(actions.setJwtToken(token));
  }, [token]);

  return <Loader />;
};
