export const AUTHORIZED_LANGUAGES = ['fr', 'en'];
export const DEFAULT_LANGUAGE = 'en';
export const LANGUAGES: { translationKey: string; value: string }[] = [
  { translationKey: 'locale.french', value: 'fr' },
  { translationKey: 'locale.english', value: 'en' },
];

// careful these constants values must correspond to the breakpoints variables defined in scss styles
const tabletLimit = 768;
const desktopLimit = 980;
export const MATCH_MEDIA_QUERY_LIST = {
  tablet: `(min-width:${tabletLimit}px)`,
  desktop: `(min-width:${desktopLimit}px)`,
};

export const GTM_INFOS: Record<
  string,
  {
    FUNNEL_NAME: string;
    INTRODUCTION_STEP_NUMBER: string;
    CONCLUSION_STEP_NUMBER: string;
  }
> = {
  'toeic-test-level-projector': {
    FUNNEL_NAME: 'placement test funnel',
    INTRODUCTION_STEP_NUMBER: '2',
    CONCLUSION_STEP_NUMBER: '22',
  },
};

export const STORAGE_KEYS = {
  JWT_TOKEN: 'jwtToken',
  AUDIO_VOLUME: 'audioVolume',
  LAST_INFO_OF_SESSION: 'lastInfoOfSession',
};
