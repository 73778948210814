import React from 'react';
import cx from 'classnames';

import { Loader } from '../Loader';

import './Button.scss';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: 'primary' | 'white';
  size?: 'small' | 'medium' | 'large';
  bold?: boolean;
  loading?: boolean;
  uppercase?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  size = 'medium',
  color = 'primary',
  loading,
  bold,
  uppercase,
  className,
  children,
  ...nativeProps
}) => {
  const disabled = loading || nativeProps.disabled;

  return (
    <button
      {...nativeProps}
      className={cx(
        'button',
        `button--${color}`,
        `button--${size}`,
        {
          'button--bold': bold,
          'button--uppercase': uppercase,
          'button--loading': loading,
          'button--disabled': disabled,
        },
        className,
      )}
      disabled={disabled}
    >
      {loading ? <Loader /> : children}
    </button>
  );
};
