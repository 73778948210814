import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';

import {
  ApplicationPage as ApplicationPageTemplate,
  ApplicationPageProps,
} from '../../components/Templates/ApplicationPage';
import { actions, useAppContext } from '../../contexts/app';
import { SessionStatusEnum } from '../../graphql/operations';
import { paths } from '../../paths';
import { useDataLayer } from '../../hooks/useDataLayer';
import { useLanguage } from '../../hooks/useLanguage';
import { getCurrentAudioElementBySession, getNextAudioElementBySession } from '../../utils';

export const ApplicationPage: React.FC = ({ children }) => {
  const [cookies] = useCookies(['CookieConsent']);

  const { t } = useTranslation();
  const { currentLanguage, languageItemLinks } = useLanguage();
  useDataLayer();
  const {
    appIsLoading,
    currentAudioIsPreloaded,
    nextAudioIsPreloaded,
    audioElements,
    playAudio,
    stopAudiosPlaying,
    currentRoute,
    session,
    audioVolume,
    modalIsOpened,
    nextStepIsDisabled,
    nextStep,
    resetTest,
    dispatch,
  } = useAppContext();

  const currentRouteIsIntroOrPartOrSet = currentRoute?.path
    ? [paths.TEST_INTRO, paths.TEST_PART, paths.TEST_SET].includes(currentRoute.path as string)
    : false;

  const onAudioVolumeChange = useCallback((volume: number) => dispatch(actions.changeVolume(volume)), [dispatch]);

  const onCloseModal = useCallback(() => {
    dispatch(actions.closeModal());
    const currentAudioElement = getCurrentAudioElementBySession(session, audioElements);
    if (currentAudioElement) {
      playAudio(currentAudioElement, true);
    }
  }, [session, audioElements, playAudio, dispatch]);

  const modalWarningProps: ApplicationPageProps['modalWarningProps'] = {
    isOpen: modalIsOpened || false,
    title: t('modal_warning.title'),
    content: t('modal_warning.description'),
    resumeButtonLabel: t('modal_warning.resume'),
    resetButtonLabel: t('modal_warning.reset'),
    resumeButtonIsLoading: !currentAudioIsPreloaded,
    onCloseModal,
    onResetTest: resetTest,
  };

  const onClickToNextStep = useCallback(() => {
    nextStep();
    const nextAudioElement = getNextAudioElementBySession(session, audioElements);

    if (nextAudioElement) {
      playAudio(nextAudioElement);
    } else {
      stopAudiosPlaying();
    }
  }, [session, audioElements, nextStep, playAudio, stopAudiosPlaying]);

  return (
    <ApplicationPageTemplate
      loading={appIsLoading}
      audioVolumeIsDisplayed={session?.status === SessionStatusEnum.InProgress && currentRouteIsIntroOrPartOrSet}
      metaTitleTemplate={t('meta.title.practice-test')}
      metaTitle={session?.test?.name}
      currentLanguageLabel={t(currentLanguage.translationKey)}
      languageItemLinks={languageItemLinks}
      onAudioVolumeChange={onAudioVolumeChange}
      audioVolume={audioVolume}
      hasCookieConsent={cookies.CookieConsent}
      legalNoticeText={
        <div dangerouslySetInnerHTML={{ __html: t('footer.legal_notice', { locale: currentLanguage.value }) }} />
      }
      termsAndConditionsText={<div dangerouslySetInnerHTML={{ __html: t('footer.terms_and_conditions') }} />}
      editCookiesSettingsText={t('footer.edit_cookies_settings')}
      copyrightText={t('footer.copyright', { year: new Date().getFullYear() })}
      hasNextStepBlock={session?.status === SessionStatusEnum.InProgress && currentRouteIsIntroOrPartOrSet}
      nextStepButtonProps={{
        loading: appIsLoading || !nextAudioIsPreloaded,
        disabled: nextStepIsDisabled,
        label: t('testing.next'),
        onClick: onClickToNextStep,
      }}
      modalWarningProps={modalWarningProps}
    >
      {children}
    </ApplicationPageTemplate>
  );
};
