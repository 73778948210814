import { createContext, Dispatch } from 'react';
import { RouteConfig } from 'react-router-config';

import { Action } from './action';
import { AnswerInput, AnswerKeyEnum, MeFragment, SessionFragment } from '../../graphql/operations';

export interface AppContextInterface {
  appIsLoading: boolean;
  currentRoute?: RouteConfig;
  jwtToken?: string;
  me?: MeFragment;
  session?: SessionFragment;
  currentAudioIsPreloaded?: boolean;
  nextAudioIsPreloaded?: boolean;
  preloadedAudios?: { [audioFileUrl: string]: boolean };
  currentPreloadedAudioFileUrl?: string;
  audioElements?: { [audioFileUrl: string]: HTMLAudioElement };
  playAudio?: (audio: HTMLAudioElement, resumeAudio?: boolean) => void;
  stopAudiosPlaying?: () => void;
  audioVolume?: number;
  answers?: AnswerInput[];
  modalIsOpened?: boolean;
  nextStepIsDisabled?: boolean;
  startTest?: () => void;
  nextStep?: () => void;
  sendAnswer?: (options: { questionId: number; answerKey: AnswerKeyEnum }) => void;
  resetTest?: () => void;
  dispatch?: Dispatch<Action>;
}

export type AppReducerState = Omit<
  AppContextInterface,
  'startTest' | 'nextStep' | 'resetTest' | 'dispatch' | 'audioElements' | 'playAudio' | 'stopAudiosPlaying'
>;

export const AppContext = createContext<AppContextInterface>({ appIsLoading: false });
