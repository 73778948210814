import { paths } from '../paths';
import { SessionFragment, SessionStatusEnum, StepTypeEnum } from '../graphql/operations';
import { generatePath } from 'react-router';

export const getPathTestBySession = ({ session, lang }: { session: SessionFragment; lang: string }): string => {
  let path = paths.ERROR;
  if (session?.status) {
    path = paths.TEST_INTRO;
    if (session.status === SessionStatusEnum.Completed) {
      path = paths.TEST_CONCLUSION;
    } else if (session.status === SessionStatusEnum.InProgress && session.currentStep?.stepType) {
      path = StepTypeEnum.Part === session.currentStep?.stepType ? paths.TEST_PART : paths.TEST_SET;
    }
  }

  return generatePath(path, { lang });
};
