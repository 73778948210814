import { AUTHORIZED_LANGUAGES } from './constants';

export const prefixLang = `/:lang(${AUTHORIZED_LANGUAGES.join('|')})?`;
const prefixTest = `${prefixLang}/tests`;

export const paths = {
  SESSION_TOKEN: `${prefixLang}/token/:token`,
  TEST_INTRO: `${prefixTest}/intro`,
  TEST_PART: `${prefixTest}/part`,
  TEST_SET: `${prefixTest}/set`,
  TEST_CONCLUSION: `${prefixTest}/conclusion`,
  ERROR: `${prefixLang}/:statusCode(403|404|500)`,
};
