import React from 'react';
import cx from 'classnames';

import './AnswerItem.scss';

export interface AnswerItemProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  questionKey: number;
  answerKey: string;
  label?: string;
  isChecked?: boolean;
  onAnswerChange: (options: { questionKey: number; answerKey: string }) => void;
}

export const AnswerItem: React.FC<AnswerItemProps> = ({
  questionKey,
  answerKey,
  onAnswerChange,
  label,
  isChecked = false,
  ...nativeProps
}) => (
  <div
    role="button"
    className={cx('answer-item', { 'answer-item--checked': isChecked })}
    onClick={() => onAnswerChange({ questionKey, answerKey })}
    onKeyDown={() => onAnswerChange({ questionKey, answerKey })}
    tabIndex={questionKey}
    data-test="answerButton"
    {...nativeProps}
  >
    <span className="answer-item__key">{answerKey}</span>
    {label && <span>{label}</span>}
  </div>
);
