import React from 'react';
import cx from 'classnames';

import { ExampleBlock, ExampleBlockProps } from './ExampleBlock/ExampleBlock';
import './PartBlock.scss';

export type PartBlockProps = {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  description: React.ReactNode;
  example?: ExampleBlockProps;
};

export const PartBlock: React.FC<PartBlockProps> = ({ title, subtitle, description, example }) => (
  <div
    className={cx('part-block', {
      'part-block--with-example': example,
    })}
  >
    <div className="part-block__content">
      <h1 className="part-block__title" data-test="partBlockTitle">
        {title}
      </h1>
      <p className="part-block__description">
        <strong>{subtitle}</strong> {description}
      </p>
    </div>
    {example && <ExampleBlock {...example} />}
  </div>
);
