import React from 'react';

import { ConclusionBlock } from '../../components/Blocks/ConclusionBlock';
import { useAppContext } from '../../contexts/app';
import { useTranslation } from 'react-i18next';

export const TestConclusionPage: React.FC = () => {
  const { t } = useTranslation();
  const { me } = useAppContext();

  const onEndTest = () => {
    window.location.href = me?.isAlreadyPassed
      ? me?.destinationUrlIsAlreadyPassed.replace(':sessionId', me?.sessionId.toString())
      : me?.destinationUrlIsNotAlreadyPassed.replace(':sessionId', me?.sessionId.toString());
  };

  return (
    <ConclusionBlock
      title={t(me?.isAlreadyPassed ? 'conclusion_page.title' : 'conclusion_page.first_test.title')}
      description={t(me?.isAlreadyPassed ? 'conclusion_page.description' : 'conclusion_page.first_test.description')}
      buttonLabel={t('conclusion_page.button')}
      onEndTest={onEndTest}
    />
  );
};
