import React, { FC } from 'react';

import { AnswerItem, AnswerItemProps } from './Item';
import './AnswerList.scss';

export type AnswerListProps = {
  onAnswerChange: AnswerItemProps['onAnswerChange'];
  questionKey: number;
  answers: {
    key: string;
    label?: string;
    isChecked?: boolean;
  }[];
};

export const AnswerList: FC<AnswerListProps> = ({ questionKey, answers, onAnswerChange }) => (
  <ul className="answer-list">
    {answers.map(({ key, isChecked, label, ...nativeProps }) => (
      <li key={key}>
        <AnswerItem
          onAnswerChange={onAnswerChange}
          questionKey={questionKey}
          answerKey={key}
          isChecked={isChecked}
          label={label}
          {...nativeProps}
        />
      </li>
    ))}
  </ul>
);
