import React from 'react';
import cx from 'classnames';

import './Skeleton.scss';

export interface SkeletonProps extends React.HTMLAttributes<HTMLDivElement> {
  circle?: boolean;
}

export const Skeleton: React.FC<SkeletonProps> = ({ circle = false, children, ...props }) => (
  <div {...props} className={cx('skeleton', { 'skeleton--circle': circle }, props.className)}>
    {children}
  </div>
);
