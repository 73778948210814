import React from 'react';

import { IconSVG, IconSVGProps } from './IconSVG';

export enum IconName {
  TIME = 'time',
  QUESTION_MARK = 'questionMark',
  VOLUME_OFF = 'volumeOff',
  VOLUME_ON = 'volumeOn',
}

export type IconProps = Omit<IconSVGProps, 'name' | 'viewBox'> & { name: IconName };

export const Icon: React.FC<IconProps> = ({ name, className, style, onClick }) => {
  let viewBox: string;
  let content: React.ReactNode;
  switch (name) {
    case IconName.TIME:
      viewBox = '0 0 42.315 51.721';
      content = (
        <g>
          <g fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.5">
            <path d="M14.81 7.764V4.437a3.2 3.2 0 013.189-3.182h6.153a3.2 3.2 0 013.187 3.187v3.327" stroke="#23477e" />
            <path d="M34.592 9.778a24.993 24.993 0 015.967 5.444" stroke="#23477e" />
            <path stroke="#23477e" d="M21.076 4.994v5.314" />
            <path d="M21.076 16.788A13.859 13.859 0 117.215 30.646" stroke="#23477e" />
            <path
              d="M40.899 30.647a19.825 19.825 0 11-19.823-19.826 19.824 19.824 0 0119.823 19.826z"
              stroke="#23477e"
            />
            <path d="M24.733 30.646a3.658 3.658 0 11-3.658-3.658 3.657 3.657 0 013.658 3.658z" stroke="#6ca6db" />
            <path stroke="#6ca6db" d="M24.539 31.961l4.221 1.573" />
            <path stroke="#6ca6db" d="M7.261 25.524l10.344 3.854" />
          </g>
        </g>
      );
      break;
    case IconName.QUESTION_MARK:
      viewBox = '0 0 50.018 50.005';
      content = (
        <g>
          <g fill="none" stroke="#23477e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5">
            <path d="M48.768 25.003A23.759 23.759 0 1125.004 1.25a23.755 23.755 0 0123.764 23.753z" />
            <path d="M25.005 7.188A17.815 17.815 0 1114.04 10.96" />
          </g>
          <g>
            <g>
              <g>
                <g>
                  <g fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M25.849 34.638a1.436 1.436 0 11-1.432-1.439 1.431 1.431 0 011.432 1.439z" />
                    <path
                      d="M24.417 36.075a1.437 1.437 0 110-2.876c.795 0 1.432.642 1.432 1.439a1.43 1.43 0 01-1.432 1.437z"
                      fill="#6ca6db"
                    />
                  </g>
                </g>
              </g>
            </g>
            <path
              d="M19.15 19.323c0-2.836 2.622-5.134 5.858-5.134s5.858 2.3 5.858 5.134c0 0 .128 2.338-2.916 4.424a8.911 8.911 0 00-3.607 6.875"
              fill="none"
              stroke="#6ca6db"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2.5"
            />
          </g>
        </g>
      );
      break;
    case IconName.VOLUME_ON:
      viewBox = '0 0 16.371 13.323';
      content = (
        <>
          <path
            d="M6.587.792L3.493 4.076a.613.613 0 00-.074 0H.629A.629.629 0 000 4.705v3.936a.629.629 0 00.629.629h2.79a.639.639 0 00.074 0l3.094 3.284a.636.636 0 001.1-.436V1.237a.636.636 0 00-1.1-.445zM16.186 4.805a9.1 9.1 0 00-1.376-3.27A9.215 9.215 0 0013.753.242a.676.676 0 10-.979.927 7.79 7.79 0 01-.029 10.982.676.676 0 10.975.932 9.222 9.222 0 001.091-1.327 9.163 9.163 0 001.376-6.951z"
            fill="#23477e"
          />
          <path
            d="M12.576 10.246a6.437 6.437 0 00-.788-8.156.676.676 0 10-.9 1.007 5.153 5.153 0 01.568.7l.049.074c.029.044.057.089.084.134l.042.07a5.051 5.051 0 01.687 2.308V7.021a5.044 5.044 0 01-.386 1.606 5.143 5.143 0 01-.26.526q-.1.173-.209.338l-.041.059a5.153 5.153 0 01-.534.647.676.676 0 10.938.969 6.5 6.5 0 00.75-.92z"
            fill="#23477e"
          />
          <path
            d="M10.685 8.102a3.735 3.735 0 00-.344-3.548 3.777 3.777 0 00-.4-.5.676.676 0 10-.979.927 2.435 2.435 0 01.266.328 2.386 2.386 0 010 2.672 2.447 2.447 0 01-.3.359.676.676 0 101.019.886 3.756 3.756 0 00.739-1.125z"
            fill="#23477e"
          />
        </>
      );
      break;
    case IconName.VOLUME_OFF:
      viewBox = '0 0 7.686 12.156';
      content = (
        <path
          d="M6.587.199L3.493 3.483a.613.613 0 00-.074 0H.629A.629.629 0 000 4.112v3.936a.629.629 0 00.629.629h2.79a.639.639 0 00.074 0l3.094 3.284a.636.636 0 001.1-.436V.644a.636.636 0 00-1.1-.445z"
          fill="#23477e"
        />
      );
      break;
    default:
      throw new Error(`Icon ${name} doesn't exist!`);
  }

  return (
    <IconSVG name={name} className={className} style={style} viewBox={viewBox} onClick={onClick}>
      {content}
    </IconSVG>
  );
};
