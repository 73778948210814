import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SetBlock, SetBlockProps } from '../../components/Blocks/SetBlock';

import { useAppContext } from '../../contexts/app';
import { AnswerKeyEnum, SetFragment, StepFragment } from '../../graphql/operations';
import { Loader } from '../../components/UI/Loader';

export const TestSetPage: FC = () => {
  const { t } = useTranslation();
  const { currentAudioIsPreloaded, session, answers, sendAnswer } = useAppContext();
  const currentStep = session?.currentStep as StepFragment & SetFragment;

  const onAnswerChange: SetBlockProps['onAnswerChange'] = useCallback(
    (options) => {
      sendAnswer({
        questionId: options.questionKey,
        answerKey: options.answerKey as AnswerKeyEnum,
      });
    },
    [sendAnswer],
  );

  const questions = useMemo((): SetBlockProps['questions'] => {
    return currentStep?.questions?.map((question) => ({
      key: question.id,
      label: question.label,
      answers: question.answers.map((answer) => ({
        key: answer.key,
        label: answer.label,
        isChecked:
          answers?.findIndex(
            (answerByQuestion) =>
              question.id === answerByQuestion.questionId && answer.key === answerByQuestion.answerKey,
          ) !== -1,
      })),
    }));
  }, [currentStep?.questions, answers]);

  if (!currentAudioIsPreloaded) {
    return <Loader />;
  }

  return (
    <SetBlock
      title={currentStep?.part?.label}
      subtitle={t('set_page.choose_correct_answer')}
      imageFileUrl={session.currentStep.imageFileUrl}
      questions={questions}
      onAnswerChange={onAnswerChange}
      data-test-step-number={session.currentStep?.stepNumber}
    />
  );
};
