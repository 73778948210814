import React, { HTMLAttributes, useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import './DropDown.scss';

export interface DropDownProps extends HTMLAttributes<HTMLDivElement> {
  label: React.ReactNode;
  items: React.ReactNode[];
  disabled?: boolean;
  className?: string;
}

export const DropDown: React.FC<DropDownProps> = ({ label, items, disabled = false, className, ...rootProps }) => {
  const [dropdownActive, setDropdownActive] = useState<boolean>();
  const DropDownRef = useRef<HTMLHeadingElement>();

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (
        DropDownRef &&
        DropDownRef.current &&
        !DropDownRef.current.contains(event!.target as Node | null) &&
        dropdownActive
      ) {
        setDropdownActive(false);
      }
    };
    document.addEventListener('click', handleClick, false);

    return () => {
      document.removeEventListener('click', handleClick, false);
    };
  });

  const handleClick = () => {
    if (disabled) {
      return;
    }

    setDropdownActive(!dropdownActive);
  };

  return (
    <div {...rootProps} className={cx('dropdown', { 'dropdown--active': dropdownActive }, className)} ref={DropDownRef}>
      <button className="dropdown__button" onClick={handleClick}>
        {label}
      </button>
      <ul className="dropdown__content">
        {items.map((item, key) => (
          <li key={key} onClick={handleClick} aria-hidden="true">
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};
