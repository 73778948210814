import React, { FC, useRef, useEffect } from 'react';

import { Icon, IconName } from '../../../../UI/Icon';

import './AudioVolume.scss';

export interface AudioVolumeProps {
  audioVolume?: number;
  onAudioVolumeChange: (volume: number) => void;
}

export const AudioVolume: FC<AudioVolumeProps> = ({ audioVolume = 1, onAudioVolumeChange }) => {
  const sliderRef = useRef(null);

  useEffect(() => {
    if (sliderRef && sliderRef.current) {
      sliderRef.current.style.background = `linear-gradient(90deg, #23477E ${audioVolume * 100}%, #D6D6D6 ${
        audioVolume * 100
      }%)`;
    }
  }, [audioVolume]);

  return (
    <div className="audio-volume">
      <Icon
        style={{ height: '12px', width: '8px', cursor: 'pointer' }}
        name={IconName.VOLUME_OFF}
        onClick={() => onAudioVolumeChange(0)}
      />
      <input
        type="range"
        id="volume"
        className="audio-volume__slider"
        ref={sliderRef}
        name="volume"
        value={audioVolume}
        onChange={(e) => onAudioVolumeChange(parseFloat(e.target.value))}
        min="0"
        max="1"
        step="0.1"
        data-test="rangeAudioVolume"
      />
      <Icon
        style={{ height: '12px', width: '16px', cursor: 'pointer' }}
        name={IconName.VOLUME_ON}
        onClick={() => onAudioVolumeChange(1)}
      />
    </div>
  );
};
