import React from 'react';

import { Button } from '../../UI/Button';
import './ConclusionBlock.scss';

export type ConclusionBlockProps = {
  title: React.ReactNode;
  description: React.ReactNode;
  buttonLabel: React.ReactNode;
  onEndTest: () => void;
};

export const ConclusionBlock: React.FC<ConclusionBlockProps> = ({ title, description, buttonLabel, onEndTest }) => (
  <div className="conclusion-block">
    <div className="conclusion-block__container">
      <p className="conclusion-block__title" data-test="conclusionBlockTitle">
        {title}
      </p>
      <p className="conclusion-block__description">{description}</p>
      <Button bold={true} uppercase={true} size="large" onClick={onEndTest}>
        {buttonLabel}
      </Button>
    </div>
  </div>
);
