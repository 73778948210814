import { Map } from 'immutable';
import { useEffect } from 'react';
import { useAppContext } from '../contexts/app';
import {
  PartFragment,
  SessionFragment,
  SessionStatusEnum,
  SetFragment,
  StepInterface,
  StepTypeEnum,
} from '../graphql/operations';
import { GTM_INFOS } from '../constants';

interface EventKey {
  event: string;
  funnelName: string;
  funnelStepName: string;
  funnelStepNumber: string;
  userStatus: string;
  templatePage: string;
  regionCountry: string;
}

export const useDataLayer = (): void => {
  const { session, me } = useAppContext();
  window.dataLayer = window.dataLayer || [];

  const pushToDataLayer = (data: Partial<EventKey>): void => {
    if (
      typeof window.dataLayer !== 'undefined' &&
      !Map(data).equals(Map(window.dataLayer[window.dataLayer.length - 1]))
    ) {
      window.dataLayer.push(data);
    }
  };

  const preparePushToDataLayerLabels = (
    session: SessionFragment,
    gtmInfo: { FUNNEL_NAME: string; INTRODUCTION_STEP_NUMBER: string; CONCLUSION_STEP_NUMBER: string },
  ) => {
    let templatePage = '';
    let funnelStepNumber = '';

    if (session.status === SessionStatusEnum.Pending) {
      templatePage = 'test introduction';
      funnelStepNumber = gtmInfo.INTRODUCTION_STEP_NUMBER;
    } else if (session.status === SessionStatusEnum.Completed) {
      templatePage = 'end of test';
      funnelStepNumber = gtmInfo.CONCLUSION_STEP_NUMBER;
    } else if (session.currentStep.stepType === StepTypeEnum.Part) {
      const currentStep = session.currentStep as StepInterface & PartFragment;
      templatePage = `introduction ${currentStep.label}`.toLowerCase();
      funnelStepNumber = `${currentStep.stepNumber}`;
    } else if (session.currentStep.stepType === StepTypeEnum.Set) {
      const currentStep = session.currentStep as StepInterface & SetFragment;
      templatePage = `${currentStep.part.label} set ${currentStep.setNumber}`.toLowerCase();
      funnelStepNumber = `${currentStep.stepNumber}`;
    }

    return [templatePage, funnelStepNumber];
  };

  useEffect(() => {
    if (me && session?.test?.slug) {
      const gtmInfo = GTM_INFOS[session?.test?.slug];
      const [templatePage, funnelStepNumber] = preparePushToDataLayerLabels(session, gtmInfo);
      if (templatePage !== '') {
        pushToDataLayer({
          event: 'dataLayerReady',
          userStatus: me.userStatus,
          templatePage,
          regionCountry: `${me.region} - ${me.country}`,
        });
      }

      if (templatePage !== '' && funnelStepNumber !== '') {
        pushToDataLayer({
          event: 'funnelEtsGlobal',
          funnelName: gtmInfo.FUNNEL_NAME,
          funnelStepName: templatePage,
          funnelStepNumber,
        });
      }
    }
  }, [location.pathname, me, session?.test?.slug]);
};
