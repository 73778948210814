import React from 'react';

import { Button, ButtonProps } from '../../UI/Button';
import { Icon, IconName } from '../../UI/Icon';
import { Skeleton } from '../../UI/Skeleton';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

import './IntroBlock.scss';

export interface IntroBlockProps {
  loading?: boolean;
  loadingStartTestButton?: boolean;
  title: React.ReactNode;
  imageFileUrl: string;
  testDurationLabel: React.ReactNode;
  numberOfQuestionsLabel: React.ReactNode;
  indicationTitle: React.ReactNode;
  indicationDescription: React.ReactNode;
  startTestButtonProps: ButtonProps;
  indicationCommercialOffersDescription: string;
}

export const IntroBlock: React.FC<IntroBlockProps> = ({
  loading = false,
  title,
  imageFileUrl,
  testDurationLabel,
  numberOfQuestionsLabel,
  indicationTitle,
  indicationDescription,
  startTestButtonProps,
  indicationCommercialOffersDescription,
}) => {
  const isDesktop = useMediaQuery('desktop');

  return (
    <div className="intro-block">
      <div className="intro-block__container">
        {loading ? (
          <Skeleton className="intro-block__logo" style={{ width: 277, height: 290 }} />
        ) : (
          <img className="intro-block__logo" src={imageFileUrl} alt="logo" />
        )}

        <div className="intro-block__content">
          <h3 className="intro-block__title">
            {loading ? <Skeleton style={{ width: isDesktop ? 800 : 245, height: 85 }} /> : title}
          </h3>
          <div className="intro-block__indication-container">
            <div className="intro-block__indication-numbers">
              <div className="intro-block__indication-icon">
                {loading ? (
                  <Skeleton style={{ width: 185, height: 45 }} />
                ) : (
                  <>
                    <Icon name={IconName.TIME} style={{ marginRight: '1rem', height: '50px', width: '50px' }} />
                    {testDurationLabel}
                  </>
                )}
              </div>
              <div className="intro-block__indication-icon">
                {loading ? (
                  <Skeleton style={{ width: 185, height: 45 }} />
                ) : (
                  <>
                    <Icon
                      name={IconName.QUESTION_MARK}
                      style={{ marginRight: '1rem', height: '50px', width: '50px' }}
                    />
                    {numberOfQuestionsLabel}
                  </>
                )}
              </div>
            </div>
            <div className="intro-block__indication-text">
              <h4 className="intro-block__indication-title" data-test="introIndicationBlockTitle">
                {loading ? <Skeleton style={{ width: isDesktop ? 250 : 245, height: 30 }} /> : indicationTitle}
              </h4>
              {loading ? (
                <Skeleton style={{ width: isDesktop ? 400 : 245, height: isDesktop ? 75 : 170 }} />
              ) : (
                <ul className="intro-block__indication-list">{indicationDescription}</ul>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="intro-block__indication-commercial-offer">{indicationCommercialOffersDescription}</div>
      <div className="intro-block__button">
        <Button size="large" bold data-test="startTestButton" {...startTestButtonProps} />
      </div>
    </div>
  );
};
