import React from 'react';

import { AnswerList, AnswerListProps } from './AnswerList';
import { AnswerItemProps } from './AnswerList/Item';
import './SetBlock.scss';

export interface SetBlockProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  subtitle: string;
  imageFileUrl?: string;
  questions: {
    key: number;
    label: string;
    answers: AnswerListProps['answers'];
  }[];
  onAnswerChange: AnswerItemProps['onAnswerChange'];
}

export const SetBlock: React.FC<SetBlockProps> = ({
  title,
  subtitle,
  imageFileUrl,
  questions,
  onAnswerChange,
  ...nativeProps
}) => (
  <div className="set-block" {...nativeProps}>
    <div className="set-block__title" data-test="setBlockTitle">
      {title}
    </div>
    <div className="set-block__container">
      <div className="set-block__subtitle">{subtitle}</div>
      <div className="set-block__content">
        {imageFileUrl ? (
          <div className="set-block__image">
            <img src={imageFileUrl} alt="question" />
          </div>
        ) : null}
        <div className="set-block__questions">
          {questions.map((question, key) => (
            <div key={key}>
              <div className="set-block__question-title">
                {question.key}. {question.label}
              </div>
              <AnswerList questionKey={question.key} answers={question.answers} onAnswerChange={onAnswerChange} />
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);
