import React from 'react';

import logo from '../../../../assets/images/logo.svg';
import { DropDown } from '../../../UI/DropDown';
import { AudioVolume, AudioVolumeProps } from './AudioVolume';

import './Header.scss';

export interface HeaderProps extends Partial<AudioVolumeProps> {
  currentLanguageLabel: React.ReactNode;
  languageItemLinks: React.ReactNode[];
  audioVolumeIsDisplayed?: boolean;
}

export const Header: React.FC<HeaderProps> = ({
  currentLanguageLabel,
  languageItemLinks,
  audioVolumeIsDisplayed = false,
  audioVolume,
  onAudioVolumeChange,
}) => (
  <header className="header">
    <div className="header__top">
      <DropDown
        className="header__language-switch"
        label={currentLanguageLabel}
        items={languageItemLinks}
        data-test="switchLang"
      />
    </div>
    <div className="header__bottom">
      <img src={logo} alt="logo" />
      {audioVolumeIsDisplayed && <AudioVolume audioVolume={audioVolume} onAudioVolumeChange={onAudioVolumeChange} />}
    </div>
  </header>
);
