import React, { SVGAttributes } from 'react';

// eslint-disable-next-line no-undef
export interface IconSVGProps extends SVGAttributes<HTMLOrSVGElement> {
  name: string;
}

export const IconSVG: React.FC<IconSVGProps> = ({ children, className, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
      className={`icon icon--${name} ${className}`}
    >
      {children}
    </svg>
  );
};
