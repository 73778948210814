import React, { useCallback } from 'react';

import logo from '../../assets/images/test-logo.png';

import { IntroBlock } from '../../components/Blocks/IntroBlock';
import { useAppContext } from '../../contexts/app';
import { useTranslation } from 'react-i18next';
import { getNextAudioElementBySession } from '../../utils';

export const TestIntroPage: React.FC = () => {
  const { t } = useTranslation();
  const { nextAudioIsPreloaded, audioElements, playAudio, session, startTest } = useAppContext();

  const onClickToStartTest = useCallback(() => {
    startTest();
    window.scrollTo(0, 0);
    const nextAudioElement = getNextAudioElementBySession(session, audioElements);
    if (nextAudioElement) {
      playAudio(nextAudioElement);
    }
  }, [session, audioElements, startTest, playAudio]);

  return (
    <IntroBlock
      title={t('introduction_page.title', { testName: session.test.name })}
      imageFileUrl={logo}
      testDurationLabel={t('introduction_page.test_duration')}
      numberOfQuestionsLabel={t('introduction_page.number_of_questions')}
      indicationTitle={t('introduction_page.indication_title')}
      indicationDescription={<div dangerouslySetInnerHTML={{ __html: t('introduction_page.indication_content') }} />}
      startTestButtonProps={{
        loading: !nextAudioIsPreloaded,
        onClick: onClickToStartTest,
        children: t('introduction_page.start_test'),
      }}
      indicationCommercialOffersDescription={t('introduction_page.indication_commercial_offers.description')}
    />
  );
};
