import React from 'react';
import { renderRoutes, RouteConfig } from 'react-router-config';

import { prefixLang, paths } from './paths';
import { ApplicationPage, TestIntroPage, TestPartPage, TestSetPage, TestConclusionPage } from './pages/ApplicationPage';
import { ErrorPage } from './pages/ErrorPage';

import { SessionTokenPage } from './pages/SessionTokenPage';

export const routes: RouteConfig[] = [
  {
    path: paths.SESSION_TOKEN,
    component: SessionTokenPage,
  },
  {
    path: prefixLang,
    render: ({ route }) => <ApplicationPage>{renderRoutes(route.routes)}</ApplicationPage>,
    routes: [
      {
        path: paths.TEST_INTRO,
        component: TestIntroPage,
      },
      {
        path: paths.TEST_PART,
        component: TestPartPage,
      },
      {
        path: paths.TEST_SET,
        component: TestSetPage,
      },
      {
        path: paths.TEST_CONCLUSION,
        component: TestConclusionPage,
      },
      {
        path: paths.ERROR,
        component: ErrorPage,
      },
      {
        component: ErrorPage,
      },
    ],
  },
];
